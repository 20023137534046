<template>
  <div>
    <div class="table-loader-man-hour">
      <div
        class="text-center"
        v-bind:class="{
          'd-none': loadingActive
        }"
      >
        <div class="text-center">
          <div class="spinner-border text-secondary" role="status">
            <span class="visually-hidden"></span>
          </div>
        </div>
      </div>
    </div>

    <div
      v-bind:class="{
        'd-none': tableDocumentSiteProject
      }"
    >
      <div class="card card-custom p-5 gutter-b">
        <div class="card-body p-0">
          <div class="row">
            <div class="col-md-6 py-2 col-sm-12">
              <label for="input-large">Project Code</label>

              <b-form-select
                id="input-lg"
                value-field="nickname"
                text-field="nickname"
                size="lg"
                v-model="selectNickname"
              >
                <b-form-select-option
                  v-for="(proj, i) in this.projectList"
                  v-bind:key="i"
                  :value="{ code: proj.code, text: proj.nickname }"
                >
                  {{ proj.nickname }}
                </b-form-select-option>
              </b-form-select>
            </div>

            <div class="col-md-6 py-2 col-sm-12">
              <label for="input-large">Project Leader Name</label>

              <b-form-input
                id="input-large"
                size="lg"
                disabled
                placeholder="Project Leader Name"
                v-model="selectedProject.primpro"
              ></b-form-input>
            </div>

            <div class="col-md-6 py-2 col-sm-12">
              <label for="input-large">Project Name</label>

              <b-form-input
                id="input-large"
                size="lg"
                disabled
                placeholder="Project Name"
                v-model="selectedProject.projectName"
              ></b-form-input>
            </div>

            <div class="col-md-6 py-2 col-sm-12">
              <label for="input-large">Revision</label>

              <b-form-input
                id="input-large"
                size="lg"
                placeholder="Revision"
                v-model="selectedProject.revisi"
              ></b-form-input>
            </div>
          </div>
          <div class="d-flex justify-content-end mt-3">
            <b-button
              variant="success"
              @click="simpan()"
              ref="kt_simpan_project"
            >
              <i class="flaticon2-check-mark"></i> Save
            </b-button>
          </div>
        </div>
      </div>

      <div class="card card-custom p-5 gutter-b">
        <div class="card-body p-0">
          <document-site-project-item
            :projectSelected="this.selectedProject"
            :dspItems="this.data.dspItems"
            @setDspUpdateItems="setDspUpdateItems"
            @setDspItems="setDspItems"
          ></document-site-project-item>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import DocumentSiteProjectItem from "./document-site-project/DocumentSiteProjectItem.vue";
import { mapGetters } from "vuex";
import {
  SHOW_PROJECT_ADMIN,
  SHOW_PROJECT_INTERFACE
} from "@/core/services/store/dl.design.module.js";
import { GET_SITE_DOCUMENT } from "@/core/services/store/site-document.module";
import {
  GET_DOCUMENT_SITE_PROJECT,
  POST_DOCUMENT_SITE_PROJECT
} from "@/core/services/store/document-site-project.module";

export default {
  name: "DocumentSiteProject",
  components: {
    DocumentSiteProjectItem
  },
  data() {
    return {
      filter: {
        status: null,
        name: null,
        code: null,
        owner: null,
        contractNumber: null,
        nickname: null,
        station: null,
        scope: null
      },
      selectedProject: {
        code: null,
        ownerName: null,
        projectName: null,
        status: null,
        contractNumber: null,
        primpro: null,
        revisi: null
      },
      selectNickname: {
        code: null,
        text: null,
      },
      options: {
        statuses: [
          { value: null, text: "Choose Status" },
          { value: "open", text: "Open" },
          { value: "close", text: "Close" }
        ],
        nicknames: [{ value: null, text: "Choose Nickname" }],
        stations: [{ value: null, text: "Choose Stasiun" }],
        scopes: [{ value: null, text: "Choose Scope" }]
      },
      data: {
        projects: [
          {
            code: "LR98123",
            name: "PEMBANGUNAN SISTEM PERSINYALAN",
            status: "Open",
            owner: "BALAI TEKNIK PERKERETAAPIAN"
          }
        ],
        dspItems: [],
        dspItemsUpdated: [],
        dsp: {
          project: {
            code: null,
            name: null,
            site_document_revision: null
          },
          site_document: []
        }
      },
      loadingActive: false,
      tableDocumentSiteProject: true
    };
  },
  methods: {

    merge(a, b, prop) {
      var reduced = a.filter(
        (aitem) => !b.find((bitem) => aitem[prop] === bitem[prop])
      );
      return reduced.concat(b);
    },

    simpan() {
      // set spinner to submit button
      const submitButton = this.$refs["kt_simpan_project"];
      console.log(submitButton);
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");

      Object.assign(this.data.dsp.project, {
        code: this.selectedProject.code,
        name: this.selectedProject.projectName,
        site_document_revision: this.selectedProject.revisi
      });

      /*   this.data.dspItemsUpdated.map(updated => {
        this.data.dsp.site_document.push({
          id: updated.flag === "NEW" ? null : updated.id,
          site_document_type: updated.site_document_type,
          complete: updated.complete,
          storage: updated.storage
        });
      }); */

      setTimeout(() => {
        this.$store
          .dispatch(POST_DOCUMENT_SITE_PROJECT, this.data.dsp)
          .then(() => this.uploadDocument());

        submitButton.classList.remove(
          "spinner",
          "spinner-light",
          "spinner-right"
        );
      }, 2000);
    },

    uploadDocument() {
      console.log("result dsp", this.currentResultDsp);
      this.getDocumentSiteByProject(this.selectedProject.code);
    },

    setDspUpdateItems(dspItems) {
      if (this.data.dspItemsUpdated.length > 0) {
        if (
          this.data.dspItemsUpdated.filter(c => c.id === dspItems.id).length > 0
        ) {
          this.data.dspItemsUpdated = this.data.dspItemsUpdated.filter(
            c => c.id !== dspItems.id
          );
        }
      }
      this.data.dspItemsUpdated.push(dspItems);
    },

    setDspItems(listDspItems) {
      console.log("list", listDspItems);
      this.data.dspItems = [];
      listDspItems.map(item => {
        this.data.dspItems.push({
          id: item.id,
          site_document_type: {
            code: item.site_document_type ? item.site_document_type.code : null,
            name: item.site_document_type ? item.site_document_type.name : null,
            category: {
              code: item.site_document_type
                ? item.site_document_type.category.code
                : null,
              name: item.site_document_type
                ? item.site_document_type.category.name
                : null
            }
          },
          site_document_project: item.site_document_project,
          complete: item.complete,
          storage: item.storage,
          flag: item.flag,
          site_document_date: item.site_document_date,
          description: item.description,
          note: item.note
        });
      });
    },

    getDocumentSiteByProject(code) {
      this.data.dspItems = [];
      this.$store
        .dispatch(GET_DOCUMENT_SITE_PROJECT, { project_code: code })
        .then(() => this.setDspItems(this.currentDocumentSiteProjects));
    }
  },
  async created() {
    await this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Document Site Project" }
    ]);

    await this.$store.dispatch(SHOW_PROJECT_ADMIN);
    await this.$store.dispatch(SHOW_PROJECT_INTERFACE);

    await this.$store.dispatch(GET_SITE_DOCUMENT);

    this.loadingActive = true;
    this.tableDocumentSiteProject = false;
  },
  computed: {
    ...mapGetters([
      "currentProjectDetails",
      "currentDocumentSiteProjects",
      "currentResultDsp"
    ]),
    projectOptions() {
      var result = [{ value: null, text: "Choose Project" }];
      if (this.currentProjectDetails != undefined) {
        this.currentProjectDetails.map(project => {
          result.push({
            value: project.code,
            text: project.code + "-" + project.nickname
          });
        });
      }
      return result;
    },
    currentProjectCode() {
      return this.selectNickname.code;
    },

    projectList() {
      let projectAdmin = this.$store.getters.getterProjectAdmin;
      let projectInterface = this.$store.getters.getterProjectInterface;
      return this.merge(projectAdmin, projectInterface, "code");
    }
  },
  watch: {
    currentProjectCode(value) {
      if (!value) {
        Object.assign(this.selectedProject, {
          code: null,
          ownerName: null,
          projectName: null,
          status: null,
          contractNumber: null,
          revisi: null,
          primpro: null
        });

        return;
      }

      var resultProject = this.projectList.filter(
        c => c.code === value
      );

      Object.assign(this.selectedProject, {
        code: resultProject[0].code,
        ownerName: resultProject[0].owner,
        projectName: resultProject[0].name,
        status: resultProject[0].status,
        contractNumber: resultProject[0].contract_no,
        primpro: resultProject[0].leader,
        revisi: resultProject[0].site_document_revision
      });

      this.getDocumentSiteByProject(value);
    }
  }
};
</script>

<style></style>
