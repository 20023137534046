<template>
  <div>
    <div class="d-flex my-3 justify-content-end">
      <button class="btn btn-success btn-md" @click="add()">
        <i class="flaticon-add"></i>
      </button>
    </div>

    <div class="table-responsive mt-5">
      <table
        class="table table-head-custom table-vertical-center table-head-bg table-borderless"
      >
        <thead>
          <tr class="text-left">
            <th class="pl-7" style="min-width: 250px">
              <span class="text-dark-75">Item</span>
            </th>
            <th class="pl-7" style="min-width: 80px">
              <span class="text-dark-75">Completeness</span>
            </th>
            <th class="pl-7" style="min-width: 100px">
              <span class="text-dark-75">Storage</span>
            </th>
            <th class="pl-7" style="min-width: 150px">
              <span class="text-dark-75">Date</span>
            </th>
            <th class="pl-7" style="min-width: 150px">
              <span class="text-dark-75">Description</span>
            </th>
            <th class="pl-7" style="min-width: 150px">
              <span class="text-dark-75">Note</span>
            </th>
            <th style="min-width: 150px">Action</th>
          </tr>
        </thead>

        <template v-if="dspItems.length >= 1 && dspItems.length < 5">
          <div class="text-center" style="height: 15rem;"></div>
          <tbody>
            <template v-for="(dsp, i) in dspItems">
              <tr v-bind:key="i">
                <td class="pl-0 ">
                  <template v-if="currentIndex !== i">
                    <span
                      class="ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"
                    >
                      {{ getFullItemName(dsp.site_document_type.code) }}
                    </span>
                  </template>

                  <template v-if="currentIndex === i">
                    <b-form-select
                      v-model="dsp.site_document_type.code"
                      :options="siteDocumentOptions"
                      size="lg"
                    ></b-form-select>
                  </template>
                </td>

                <td class="pl-0 ">
                  <template v-if="currentIndex !== i">
                    <span
                      class="ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"
                    >
                      <i
                        v-if="dsp.complete > 0"
                        class="flaticon2-check-mark"
                      ></i>
                    </span>
                  </template>

                  <template v-if="currentIndex === i">
                    <b-form-checkbox
                      class="mx-auto"
                      size="lg"
                      v-model="dsp.complete"
                    ></b-form-checkbox>
                  </template>
                </td>

                <td class="pl-0 ">
                  <template v-if="currentIndex !== i">
                    <span
                      class="ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"
                    >
                      <template
                        v-if="
                          dsp.storage !== null
                            ? dsp.storage.includes('ftp')
                            : false
                        "
                      >
                        <a
                          class="btn btn-light-primary font-weight-bolder font-size-sm ml-2"
                          @click="
                            downloadFile(
                              dsp.storage,
                              getFullItemName(dsp.site_document_type.code)
                            )
                          "
                          >{{ getFullItemName(dsp.site_document_type.code) }}</a
                        >
                      </template>
                    </span>
                  </template>

                  <template v-if="currentIndex === i">
                    <b-form-file
                      class="mt-3"
                      plain
                      @change="selectedFile($event)"
                    ></b-form-file>
                  </template>
                </td>

                <td class="pl-0 ">
                  <template v-if="currentIndex !== i">
                    <span
                      class="ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"
                    >
                      {{ 
                        moment(dsp.site_document_date).isValid() ? 
                        moment(dsp.site_document_date).format("DD-MM-YYYY") : "" }}
                    </span>
                  </template>

                  <template v-if="currentIndex === i">
                    <b-input-group>
                      <b-form-input
                        id="input-large"
                        size="lg"
                        type="text"
                        v-model="dsp.site_document_date"
                        placeholder="YYYY-MM-DD"
                      ></b-form-input>
                      <b-input-group-append>
                        <b-form-datepicker
                          size="lg"
                          button-only
                          right
                          v-model="dsp.site_document_date"
                          reset-button
                          reset-value=""
                          :no-flip="true"
                        ></b-form-datepicker>
                      </b-input-group-append>
                    </b-input-group>
                  </template>
                </td>

                <td class="pl-0 ">
                  <template v-if="currentIndex !== i">
                    <span
                      class="ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"
                    >
                      {{ dsp.description }}
                    </span>
                  </template>

                  <template v-if="currentIndex === i">
                    <b-form-input
                      id="input-large"
                      size="lg"
                      v-model="dsp.description"
                    ></b-form-input>
                  </template>
                </td>

                <td class="pl-0 ">
                  <template v-if="currentIndex !== i">
                    <span
                      class="ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"
                    >
                      {{ dsp.note }}
                    </span>
                  </template>

                  <template v-if="currentIndex === i">
                    <b-form-input
                      id="input-large"
                      size="lg"
                      v-model="dsp.note"
                    ></b-form-input>
                  </template>
                </td>

                <td class="pl-0 ">
                  <template v-if="currentIndex !== i">
                    <a
                      class="btn btn-light-success font-weight-bolder font-size-sm"
                      @click="edit(i)"
                    >
                      <i class="flaticon-edit"></i>
                    </a>
                    <a
                      class="btn btn-light-danger font-weight-bolder font-size-sm ml-2"
                      @click="remove(dsp)"
                    >
                      <i class="flaticon2-trash"></i>
                    </a>
                  </template>

                  <template v-if="currentIndex === i">
                    <a
                      class="btn btn-light-success font-weight-bolder font-size-sm"
                      @click="update(i, dsp, $event)"
                      ref="kt_simpan_item"
                    >
                      <i class="flaticon2-check-mark"></i>
                    </a>

                    <a
                      class="btn btn-light-danger font-weight-bolder font-size-sm ml-3"
                      @click="cancelEdit()"
                    >
                      <i class="flaticon2-cancel-music"></i>
                    </a>
                  </template>
                </td>
              </tr>
            </template>
          </tbody>
        </template>

        <template v-else>
          <tbody>
            <template v-for="(dsp, i) in dspItems">
              <tr v-bind:key="i">
                <td class="pl-0 ">
                  <template v-if="currentIndex !== i">
                    <span
                      class="ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"
                    >
                      {{ getFullItemName(dsp.site_document_type.code) }}
                    </span>
                  </template>

                  <template v-if="currentIndex === i">
                    <b-form-select
                      v-model="dsp.site_document_type.code"
                      :options="siteDocumentOptions"
                      size="lg"
                    ></b-form-select>
                  </template>
                </td>

                <td class="pl-0 ">
                  <template v-if="currentIndex !== i">
                    <span
                      class="ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"
                    >
                      <i
                        v-if="dsp.complete > 0"
                        class="flaticon2-check-mark"
                      ></i>
                    </span>
                  </template>

                  <template v-if="currentIndex === i">
                    <b-form-checkbox
                      class="mx-auto"
                      size="lg"
                      v-model="dsp.complete"
                    ></b-form-checkbox>
                  </template>
                </td>

                <td class="pl-0 ">
                  <template v-if="currentIndex !== i">
                    <span
                      class="ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"
                    >
                      <template
                        v-if="
                          dsp.storage !== null
                            ? dsp.storage.includes('ftp')
                            : false
                        "
                      >
                        <a
                          class="btn btn-light-primary font-weight-bolder font-size-sm ml-2"
                          @click="
                            downloadFile(
                              dsp.storage,
                              getFullItemName(dsp.site_document_type.code)
                            )
                          "
                          >{{ getFullItemName(dsp.site_document_type.code) }}</a
                        >
                      </template>
                    </span>
                  </template>

                  <template v-if="currentIndex === i">
                    <b-form-file
                      class="mt-3"
                      plain
                      @change="selectedFile($event)"
                    ></b-form-file>
                  </template>
                </td>

                <td class="pl-0 ">
                  <template v-if="currentIndex !== i">
                    <span
                      class="ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"
                    >
                      {{
                        moment(dsp.site_document_date).isValid() ?  
                        moment(dsp.site_document_date).format("DD-MM-YYYY") : "" }}
                    </span>
                  </template>

                  <template v-if="currentIndex === i">
                    <b-input-group>
                      <b-form-input
                        id="input-large"
                        size="lg"
                        type="input"
                        v-model="dsp.site_document_date"
                        placeholder="YYYY-MM-DD"
                      ></b-form-input>
                      <b-input-group-append>
                        <b-form-datepicker
                          size="lg"
                          button-only
                          right
                          v-model="dsp.site_document_date"
                          reset-button
                          reset-value=""
                          :no-flip="true"
                        ></b-form-datepicker>
                      </b-input-group-append>
                    </b-input-group>
                  </template>
                </td>

                <td class="pl-0 ">
                  <template v-if="currentIndex !== i">
                    <span
                      class="ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"
                    >
                      {{ dsp.description }}
                    </span>
                  </template>

                  <template v-if="currentIndex === i">
                    <b-form-input
                      id="input-large"
                      size="lg"
                      v-model="dsp.description"
                    ></b-form-input>
                  </template>
                </td>

                <td class="pl-0 ">
                  <template v-if="currentIndex !== i">
                    <span
                      class="ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"
                    >
                      {{ dsp.note }}
                    </span>
                  </template>

                  <template v-if="currentIndex === i">
                    <b-form-input
                      id="input-large"
                      size="lg"
                      v-model="dsp.note"
                    ></b-form-input>
                  </template>
                </td>

                <td class="pl-0 ">
                  <template v-if="currentIndex !== i">
                    <a
                      class="btn btn-light-success font-weight-bolder font-size-sm"
                      @click="edit(i)"
                    >
                      <i class="flaticon-edit"></i>
                    </a>
                    <a
                      class="btn btn-light-danger font-weight-bolder font-size-sm ml-2"
                      @click="remove(dsp)"
                    >
                      <i class="flaticon2-trash"></i>
                    </a>
                  </template>

                  <template v-if="currentIndex === i">
                    <a
                      class="btn btn-light-success font-weight-bolder font-size-sm"
                      @click="update(i, dsp, $event)"
                      ref="kt_simpan_item"
                    >
                      <i class="flaticon2-check-mark"></i>
                    </a>

                    <a
                      class="btn btn-light-danger font-weight-bolder font-size-sm ml-3"
                      @click="cancelEdit()"
                    >
                      <i class="flaticon2-cancel-music"></i>
                    </a>
                  </template>
                </td>
              </tr>
            </template>
          </tbody>
        </template>
      </table>

      <div class="d-flex justify-content-center mt-5 float-left">
        <h4 align="left">
          Total Data : {{ dspItems.length }} / {{ dspItems.length }}
        </h4>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import {
  GET_DOCUMENT_SITE_PROJECT,
  POST_DOCUMENT_SITE_PROJECT,
  POST_UPLOAD_DOCUMENT_SITE_PROJECT,
  DEL_DOCUMENT_SITE_PROJECT,
} from "@/core/services/store/document-site-project.module";
import { showToast } from "@/core/helper/toast.helper";
import { deleteModal } from "../../../../core/helper/toast.helper";
import ApiService from "../../../../core/services/api.service";

export default {
  name: "DocumentSiteProjectItem",
  props: {
    dspItems: { type: Array, required: true },
    projectSelected: { type: Object, required: true },
  },
  data() {
    return {
      dspItem: {
        id: null,
        item: null,
        kelengkapan: null,
        storage: null,
        site_document_date: null,
        description: null,
        note: null,
      },
      currentIndex: null,
      selectedFiles: undefined,
      data: {
        dsp: {
          project: { code: null, name: null, site_document_revision: null },
          site_document: [],
        },
      },
      oldData: {},
    };
  },
  methods: {
    edit(index) {
      this.currentIndex = index; //this.oldData =
      this.dspItems[index];
      this.oldData = this.dspItems[index];
    },

    cancelEdit() {
      //this.data.projects[this.currentIndex] = this.oldData;
      this.dspItems[this.currentIndex] = this.oldData;
      this.currentIndex = null;
      this.onRefresh();
    },

    selectedFile(event) {
      this.selectedFiles = event.target.files[0];
    },

    remove(dspItem) {
      const deleteFunction = () => {
        this.$store
          .dispatch(DEL_DOCUMENT_SITE_PROJECT, dspItem.id)
          .then(this.onRefresh);
      };

      deleteModal(deleteFunction);
    },

    add() {
      const dspItems = this.dspItems;
      //var id = dspItems.reduce((acc, shot) => acc = acc > shot.id ? acc : shot.id, 0);
      dspItems.push({
        id: null,
        site_document_type: {
          code: null,
          name: null,
          category: {
            code: null,
            name: null,
          },
        },
        complete: 0,
        storage: null,
        flag: "NEW",
      });

      this.$emit("setDspItems", dspItems);
    },

    update(index, dspItem, event) {
      const dspItems = this.dspItems;
      dspItems[index] = dspItem;
      // this.$emit("setDspUpdateItems", dspItem);

      event.target.classList.add(
        "flaticon2-check-mark",
        "spinner",
        "spinner-light",
        "spinner-center"
      );

      Object.assign(this.data.dsp.project, {
        code: this.projectSelected.code,
        name: this.projectSelected.projectName,
        site_document_revision: this.projectSelected.revisi,
      });

      this.data.dsp.site_document = [];
      this.data.dsp.site_document.push({
        id: dspItem.id != null ? dspItem.id : null,
        site_document_type: dspItem.site_document_type,
        complete: dspItem.complete,
        storage: "/path/to/file.txt",
        site_document_date:
          dspItem.site_document_date != null
            ? dspItem.site_document_date
            : null,
        description: dspItem.description != null ? dspItem.description : "",
        note: dspItem.note != null ? dspItem.note : "",
      });

      setTimeout(() => {
        this.$store
          .dispatch(POST_DOCUMENT_SITE_PROJECT, this.data.dsp)
          .then(() => this.uploadDocument(this.selectedFiles, event));
      }, 2000);
    },

    onRefresh() {
      this.$store
        .dispatch(GET_DOCUMENT_SITE_PROJECT, {
          project_code: this.projectSelected.code,
        })
        .then(() => {
          this.$emit("setDspItems", this.currentDocumentSiteProjects);
          this.setDspItems(this.currentDocumentSiteProjects);
        });
    },

    setDspItems(listDspItems) {
      this.data.dspItems = [];
      listDspItems.map((item) => {
        this.data.dspItems.push({
          id: item.id,
          site_document_type: {
            code: item.site_document_type ? item.site_document_type.code : null,
            name: item.site_document_type ? item.site_document_type.name : null,
            category: {
              code: item.site_document_type
                ? item.site_document_type.category.code
                : null,
              name: item.site_document_type
                ? item.site_document_type.category.name
                : null,
            },
          },
          site_document_project: item.site_document_project,
          complete: item.complete,
          storage: item.storage,
          flag: item.flag,
          site_document_date: item.site_document_date,
          description: item.description,
          note: item.note,
        });
      });
    },

    uploadDocument(files, event) {
      if (files !== undefined) {
        let siteDocument = this.currentResultDsp.site_document[0];
        let formData = new FormData();
        formData.append("file", files);
        formData.append("project_code", this.projectSelected.code);
        formData.append("document_site_project_id", siteDocument.id); //
        this.$store
          .dispatch(POST_UPLOAD_DOCUMENT_SITE_PROJECT, formData)
          .then(() => {
            this.onRefresh();
            this.currentIndex = null;
          })
          .catch((error) => {
            showToast("Notification", error.data.message, "danger");
            event.target.classList.remove(
              "spinner",
              "spinner-light",
              "spinner-center"
            );
          });
      } else {
        this.onRefresh();
        this.currentIndex = null;
      }
    },

    getFullItemName(code) {
      var result = this.siteDocumentOptions.find((x) => x.value === code);
      if (result !== undefined) return result.text;

      return "";
    },
    downloadFile(url, fileName) {
      ApiService.setHeader();

      ApiService.postDownload("ftp/download", {
        file_url: url,
      })
        .then((response) => {
          const fileURL = window.URL.createObjectURL(
            new Blob([response?.data])
          );
          //start
          // const fileLink = document.createElement("a");

          // fileLink.href = fileURL;

          // fileLink.setAttribute("download", fileName);

          // document.body.appendChild(fileLink);

          // fileLink.click();
          //end
          
          const contentDisposition = response.headers['content-disposition'];

          var filenameMatch = "";
          
          var regex = /filename="([^"]+)"/;
          var match = regex.exec(contentDisposition);
          if(match){
            filenameMatch = match[1];
          }

          const fileLink = document.createElement("a");
          console.log("file name ", fileName);
          fileLink.href = fileURL;
          fileLink.setAttribute("download", filenameMatch);

          document.body.appendChild(fileLink);

          fileLink.click();
        })
        .catch(() => {
          showToast("Error", "Download Failed, Please Reupload File", "danger");
        });
    },
  },

  computed: {
    ...mapGetters([
      "currentSiteDocument",
      "currentResultDsp",
      "currentDocumentSiteProjects",
    ]),

    siteDocumentOptions() {
      return this.currentSiteDocument.map((cmp) => {
        return {
          value: cmp.code,
          text: cmp.code + "-" + cmp.item,
        };
      });
    },
  },
};
</script>
